import React, { Component } from "react"
import loadable from '@loadable/component'
import Fade from 'react-reveal/Fade';
class BaseComponent extends Component {
  getModuleName = (moduleName) => {
    let compName = null;
    if (/ContentfulBanner/.test(moduleName)) {
      compName = "GenericBanner/genericbannercontainer";
    }
    if (/ContentfulBannersList/.test(moduleName)) {
      compName = "BannersList/bannersList";
    }

    if (/ContentfulBlockList/.test(moduleName)) {
      compName = "BlockList/blocklist";
    }

    if (/ContentfulBiLateralBlock/.test(moduleName)) {
      compName = "BiLateralBlock/bilateralblock";
    }

    if (/ContentfulTextComponent/.test(moduleName)) {
      compName = "TextComponent/textComponent";
    }

    if (/ContentfulCta/.test(moduleName)) {
      compName = "flbuttonlink";
    }

    if (/ContentfulGeneralComponent/.test(moduleName)) {
      compName = "GeneralComponents/generalcomponentcontainer";
    }

    if (/ContentfulAssetComponent/.test(moduleName)) {
      compName = "AssetComponent/assetComponent";
    }

    return compName;
  }
  constructor() {
    super();
    this._loadedComponent = null;
  }

  loadComponents = () => {
    const { pageComponents, DEVICE_VIEW, pageContext } = this.props
    if (pageComponents) {
      try {
        this._loadedComponent = pageComponents.map((config, index) => {
          let moduleName = this.getModuleName(config.__typename);
          if (moduleName) {
            try {
              config.moduleName = moduleName;
              config.DEVICE_VIEW = DEVICE_VIEW;
              if (pageContext) {
                config.currentPage = pageContext.page;
                if (config.__typename === "ContentfulCta")
                  config.addWrapper = true;
              }

              const ModuleComponent = loadable(() => import(`../${moduleName}`))
              if (index < 1) {
                return <ModuleComponent key={index} {...config} />
              }
              else
                return <Fade ssrFadeout key={index}><div className={config.__typename}><ModuleComponent  {...config} /></div></Fade>
            }
            catch (e) {
              console.log(e);
              return null;
            }
          }
          return null;
        });
      }
      catch (e) {
        console.log(e);
      }
    }
    return this._loadedComponent;
  }

  render() {


    return (
      <div className="main-wrapper">
        {
          this.loadComponents()
        }
      </div>
    )
  }
}
export default BaseComponent

