import React from "react"
import Helmet from "react-helmet"

export default function SEO(props) {
  return (
    <>
      <Helmet title={props.title}>
        <meta name="description" content={props.description} />
        {props.title && <meta property="og:title" content={props.title} />}
        {props.description && <meta property="og:description" content={props.description} />}
      </Helmet>
    </>
  )
}
