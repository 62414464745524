import React, { Component } from "react"
import BaseComponent from "../components/Base"
import { graphql, navigate } from "gatsby"
import Layout from "../components/Layout/layout"
import { isLoggedIn } from "../components/Services/auth"
import SEO from "../components/seo"

class pageTemplate extends Component {
  constructor() {
    super();
    this.state = {
      DEVICE_VIEW: {
        IsMobile: false,
        IsTablet: false,
        IsDesktop: true
      }
    }
  }
  resize() {
    this.setState({
      DEVICE_VIEW: {
        IsMobile: window.innerWidth <= 719,
        IsTablet: window.innerWidth >= 720 && window.innerWidth < 960,
        IsDesktop: window.innerWidth >= 960
      }
    })
  }
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this))
    this.resize()
    // Redirecting to login page in componentDidMount because Gatsby's navigate function uses the window object (which is only available on client-side) 
    const authenticationRequired = this.props.pageContext.page.authenticationRequired;
    const shouldShowLoginPage = authenticationRequired && !isLoggedIn();
    if (shouldShowLoginPage) {
      return this.redirectToLogin(this.props.pageContext.page.slug);
    }
    
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.DEVICE_VIEW.IsMobile === nextState.DEVICE_VIEW.IsMobile
      && this.state.DEVICE_VIEW.IsDesktop === nextState.DEVICE_VIEW.IsDesktop
      && this.state.DEVICE_VIEW.IsTablet === nextState.DEVICE_VIEW.IsTablet) return false;
    return true;
  }

  redirectToLogin(nextPageSlug) {
    navigate("/login", {
      "state": {
        "next": nextPageSlug
      }
    })
    return null;
  }

  render() {
    const { DEVICE_VIEW } = this.state;
    const title = this.props.pageContext.page.title || this.props.pageContext.page.name;
    return (
      <Layout {...this.props.pageContext} DEVICE_VIEW={DEVICE_VIEW}>
        <SEO
          title={title}
          description={this.props.pageContext.page.description} />
        {this.props.data
          && this.props.data.allContentfulPage
          && this.props.data.allContentfulPage.nodes
          && this.props.data.allContentfulPage.nodes.length > 0
          && <BaseComponent
            pageContext={this.props.pageContext}
            pageComponents={this.props.data.allContentfulPage.nodes[0].components}
            DEVICE_VIEW={DEVICE_VIEW} />}
      </Layout>
    )
  }
}
export const query = graphql`
query pageContentQuery($slug: String) {
  allContentfulPage(filter: {slug: {eq: $slug}}) {
    nodes {
      name
      title
      description
      slug
      components {
        __typename
        ... on Node {
          ... on ContentfulBanner {
            ...Banner
          }
          ... on ContentfulBanner {
            ...Banner
          }
          ... on ContentfulBannersList {
            ...BannersList
          }
          ... on ContentfulBlockList {
            ...BlockList
          }
          ... on ContentfulTextComponent {
            ...TextComponent
          }
          ... on ContentfulGeneralComponent {
            ...GeneralComponent
          }
          ...on ContentfulBiLateralBlock {
            ...BiLateralBlock
          }
          ...on ContentfulBlock {
            ...Block
          }
        }
      }
    }
  }
}
fragment Banner on ContentfulBanner {
  id
  header{
    json
  }
  subtext {
      ...TextComponent
  }
  bannerStyle
  image {
    description
    fluid(maxHeight: 720) {
      ...GatsbyContentfulFluid
    }
  }
  cta {
    ...Cta
  }
}
fragment Cta on ContentfulCta {  
  text
  url  
  navigateToPage {
    slug
  }
  openInNewWindow
}
fragment BannersList on ContentfulBannersList {
  displayAsCarousel
  carouselStyle
  banners {
    ...Banner
  }
}
fragment BlockList on ContentfulBlockList {
  name
  style
  blocks {
    ... on Node {
      ...BiLateralBlock
      ...Block
    }
  }
}
fragment TextComponent on ContentfulTextComponent {
  title {
    json
  }
  description {
    json
  }
}
fragment GeneralComponent on ContentfulGeneralComponent {
  id
  componentType
  contactMessageTopic {
    title
    value
    email
  }
}
fragment Block on ContentfulBlock {
  images {
    description
    fluid {
      ...GatsbyContentfulFluid
    }
  }
  title {
    json
  }
  description {
    json
  }
  cta {
    ...Cta
  }
  sections {
    ... on Node {
      ...TextComponent
      ...Contact
    }
  }
}
fragment BiLateralBlock on ContentfulBiLateralBlock {
  style
  imagesOnLeft
  block {
    ...Block
  }
}
fragment Contact on ContentfulContact {
  name
  phone
  email
  url{
    ...Cta
  }
  location {
    ...TextComponent
  }
  instagramUrl
  twitterUrl
  facebookUrl
}
`

export default pageTemplate

